@tailwind base;
@tailwind components;
@tailwind utilities;

.animate-new-piece { 
    transition-property: transform;
    transition-duration: 1.5s;
    -moz-transition-property: -moz-transform background-color;
    -moz-transition-duration: 1.5s;
    animation-name: grow;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    -moz-animation-name: grow;
    -moz-animation-duration: 1.5s;
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: linear;
}

@keyframes grow {
0% { transform: scale(1); }
50% { transform: scale(1.2); }
100% { transform: scale(1); }
}